import React from "react";
//import AppBar from "../components/AppBar";
import band2 from "../bilderr/undergrupper/band2024.jpg";
import dans from "../bilderr/undergrupper/dans2024.jpg";
import manus from "../bilderr/undergrupper/manus2024.jpg";
import skuespiller from "../bilderr/undergrupper/skuespillere2024.jpg";
import promo from "../bilderr/undergrupper/PR2024.jpg";
import scene from "../bilderr/undergrupper/scene2024.jpg";
import kos from "../bilderr/undergrupper/kos2024.jpg";
import kostyme_sminke from "../bilderr/undergrupper/kostyme-sminke2024.jpg";

import { Grid } from "@material-ui/core";

const groups = [
  {
    Bilde: skuespiller,
    Reverse: false,
    Navn: "Skuespillere",
    Tekst:
      "Skuespillerne/skådisene/køddegutta det er oss!! Stikkord: lek, impro, sang, dans, generell kødding, rawr. Vi er de som prøver og få dere til å le, og så hadde det jo vært veldig gøy om dere gjorde det også XD. Chill og spill, skådis out",
  },
  {
    Bilde: band2,
    Reverse: true,
    Navn: "Band",
    Tekst:
      "Bændet! Gjengen som står for de musikalske godbitene som skal utfylle revyens supre sketsjer og sanginnslag. Vi har Tobias og Daniel på trommer og bass. Torkil på gitar og Aurora på keys. I tillegg har vi poteten Erlend som bidrar med en herlig kombinasjon av både gitar og keys. Til slutt har vi blåserekka bestående av Tiril, Guro og Kasper, henholdsvis på fløyte, trompet og saksofon. Vi har i løpet av det siste halvåret øvd inn masse forskjellige snutter og låter og gleder oss veldig til å vise det frem!",
  },
  {
    Bilde: manus,
    Reverse: false,
    Navn: "Manus",
    Tekst:
      "Halla mann! Vi er manusgjengen og det er oss som har skrevet denne greia her. Vi har jobbet med sketsjeskriving i et par måneder nå og er riiimelig stoked på å vise det frem til dere (håper det er bra heheh). Vi er en artig clique som trives best i køddehjørnet. Håper sketsjene ikke er for interne og rare tihi. YOLO",
  },
  {
    Bilde: dans,
    Reverse: true,
    Navn: "Dans",
    Tekst:
      "Danser, danser, danser med noen tjommier! Dette er hybridarevyens dansere! Ingvild, Jenny og Kristina skal stelle i stand et show som får hele publikum til å riste i dansefoten🕺",
  },
  {
    Bilde: promo,
    Reverse: false,
    Navn: "PR",
    Tekst: "Hei. Vi tar bilder og slikt. Håper du liker bildene, kachow.",
  },
  {
    Bilde: scene,
    Reverse: true,
    Navn: "Scene",
    Tekst: "Byggmester Bob, kan det fikses? Byggmester Bob, klart det kan!",
  },
  {
    Bilde: kos,
    Reverse: false,
    Navn: "Kos",
    Tekst:
      "Hva er det kosegruppa gjør a? Vi sørger for at det er kos og stemning, vi sprer kjærlighet, baker kaker, lager mat og arrangerer fest!",
  },
  {
    Bilde: kostyme_sminke,
    Reverse: true,
    Navn: "Kostyme/Sminke",
    Tekst:
      "Vi fikser sminke og kostymer til de på scenen, vent og se så fine de blir!"
  }

];

const Undergrupper: React.FC<any> = ({ history }) => {
  return (
    <div className="">
      {/* <header className="undergruppeheader">Undergrupper</header> */}
      <div className="undergruppe-overskrift">
        <h1 className="h1-undergruppe" style={{ color: "black" }}>
          Undergruppene 2024
        </h1>
      </div>
      <Grid container spacing={5} className="container">
        {groups.map((group) => (
          <>
            {/* Reverse */}
            {group.Reverse && (
              <>
                <Grid container className="container undergruppetekstreverse">
                  <Grid item xs={12} sm={6} md={6} key={group.Navn}>
                    <div className="">
                      <h2 id="h2_trans">{group.Navn}</h2>
                      <p id="gruppe_p2">{group.Tekst}</p>
                    </div>
                  </Grid>
                  <Grid
                    className="undergruppecard undergruppecardreverse"
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    key={group.Bilde}
                  >
                    <img className="undergruppebilde" src={group.Bilde} />
                  </Grid>
                </Grid>
              </>
            )}
            {/* IkkeReverse */}
            {group.Reverse == false && (
              <>
                <Grid container className="container undergruppetekst">
                  <Grid
                    className=""
                    style={{ backgroundColor: `#EFEBE9` }}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    key={group.Bilde}
                  >
                    <img className="undergruppebilder" src={group.Bilde} />
                  </Grid>
                  <Grid
                    className="undergruppecard undergruppecardmaincolor"
                    style={{ backgroundColor: `#EFEBE9` }}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    key={group.Navn}
                  >
                    <div className="undergruppetekst">
                      <h2>{group.Navn}</h2>
                      <p className="">{group.Tekst}</p>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        ))}
      </Grid>
    </div>
  );
};

export default Undergrupper;
