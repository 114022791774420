import React from "react";
//import AppBar from "../components/AppBar";
import { History } from "history";
import Genus from "../bilderr/genuslogo.png";
import logo from "../bilderr/logo2024.png";
import styrebilde from "../bilderr/revystyret2024.jpg";
import Actors from "../components/Actors";
import NavBar from "../components/NavBar";
import Undergruppe from "../screens/Undergrupper";

interface Props {
  history: History<any>;
}

const Home: React.FC<Props> = ({ history }) => {
  return (
    <div>
      <NavBar />
      {/*<AppBar onOmOss={() => history.push("/OmOss")} history={history}></AppBar>*/}

      <div className="front">
        <img className="header" src={logo}></img>
      </div>
      
      <div>
        <h2 className="h2-genus">I samarbeid med</h2>
        <img className="genus-logo" src={Genus} />
      </div>
      <div className="main_box">
        <div className="sub_box">
          <h1>Om oss</h1>
          <hr />
          <p>
            For tredje gang arrangerer studentene ved Ingeniørvitenskap & IKT ved
            NTNU revy! Revyen er et resultatet av arbeidet til over 100
            revyglade studenter, og det er med stolthet vi kan invitere nettopp
            deg til Verkstedhallen for å bivåne resultatet i egen person! Revyen
            har ingen aldersgrense, hverken øvre eller nede.
          </p>
          <p>
            Den inneholder med andre ord noe for alle. Kom da vel, så får du ta
            del i et lite stykke av verdenshistorien!
          </p>
          <p>Vel møtt, vi sees!</p>
        </div>
        <header className="header">Styret 2024</header>
        <img src={styrebilde} alt="test" className="styrebilde"></img>
      </div>
      <div className="actors">
        <Actors />
      </div>
      <Undergruppe />
    </div>
  );
};

export default Home;
