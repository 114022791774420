import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Hamburger from "hamburger-react";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import logo from "../bilderr/genuslogo.png";
import { margin } from "@mui/system";
import { bottom } from "@popperjs/core";

export default function NavBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  let history = useHistory();
  if (window.innerWidth > 600) {
    return (
      <>
        <BrowserView>
          <Box
            sx={{
              width: "auto",
              flexGrow: 1,
              position: "sticky",
              top: 0,
              zIndex: 9999,
            }}
          >
            <AppBar position="static" style={{ background: "#01529d" }}>
              <Toolbar variant="regular" sx={{ width: "100%" }}>
                <Button
                  variant="text"
                  style={{
                    color: "white",
                    marginTop: "20px",
                    transition: "0.2s",
                  }}
                  onClick={(e) => history.push("/program")}
                >
                  Program
                </Button>
                
                <Button
                  variant="text"
                  style={{
                    color: "white",
                    marginRight: "130px",
                    margin: "auto",
                    marginTop: "20px",
                    transition: "0.2s",
                  }}
                  onClick={(e) => window.open("https://www.youtube.com/watch?v=kX1EzOKUl24", "_blank")}
                >
                  Promovideo
                </Button>
  
                <Link href="https://genus.co/no?gad_source=1&gclid=Cj0KCQiAoeGuBhCBARIsAGfKY7x87qzczYOIjMfe4KnEsMF5YbteKH4DzOCjLO7LEge5HT4wG7E8iWUaArZ1EALw_wcB" color="inherit" style={{
                  marginBottom: "20px",
                  marginLeft: "-15px",
                }}
                
                >
                  <img className="navbar-logo" src={logo} style={{
                    scale: "180%",
                  }}/>
                </Link>
  
                <Button
                  variant="text"
                  style={{
                    color: "white",
                    margin: "auto",
                    marginTop: "20px",
                    transition: "0.2s",
                  }}
                  onClick={() => {
                    window.location.href = "https://www.ntnu.no/studier/mting";
                  }}
                >
                  Hvem er vi?
                </Button>
                <a href="https://tikkio.com/tickets/45826-hybridarevyen-2024-det-gylne-snitt😇?fbclid=IwAR32JwItvo4duP4Ee2vz9LY_WKWYZyJPF7CbxiQz82zO8m1n6KT8TYQg6NM">
                  <Button
                    variant="text"
                    style={{
                      color: "white",
                      margin: "auto",
                      marginTop: "20px",
                      transition: "0.2s",
                    }}
                  >
                    Kjøp Billett
                  </Button>
                </a>
                <a
                  className="linkFaceInsta"
                  href="https://www.facebook.com/hybridarevyen"
                >
                  <FaFacebook
                    style={{
                      float: "right",
                      padding: "10px",
                      transition: "0.2s",
                    }}
                  />
                </a>
                <a
                  className="linkFaceInsta"
                  href="https://instagram.com/hybridarevyen?utm_medium=copy_link"
                >
                  <FaInstagram
                    style={{
                      marginRight: "30px",
                      float: "right",
                      padding: "10px",
                      transition: "0.2s",
                    }}
                  />
                </a>
              </Toolbar>
            </AppBar>
          </Box>
        </BrowserView>
      </>
      );  
  } else {
    return(
      <>
        <MobileView>
          <AppBar variant="outlined" position="static" sx={{ width: "100%" }}>
              <IconButton
                className="hamburger"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setIsDrawerOpen(true)}
              >
                <Hamburger />
              </IconButton>
              
              <Drawer
                
                style={{
                  justifyContent: "center",
                  left: 0, // Set the left position to 0 to move it to the left
                  right: "auto", // Reset the right position
                }}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
              >
                <List className={""}>
                  <ListItem button>
                    <ListItemText
                      primary="Program"
                      onClick={(e) => history.push("/program")}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemText 
                      primary="Promovideo"
                      onClick={(e) => window.open("https://www.youtube.com/watch?v=kX1EzOKUl24", "_blank")}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemText
                      primary="Bilder"
                      onClick={(e) => history.push("/Bilder")}
                    />
                  </ListItem>
                  
                  <ListItem button>
                    <a href="https://tikkio.com/tickets/45826-hybridarevyen-2024-det-gylne-snitt😇?fbclid=IwAR32JwItvo4duP4Ee2vz9LY_WKWYZyJPF7CbxiQz82zO8m1n6KT8TYQg6NM">
                      <ListItemText primary="Kjøp billett" />
                    </a>
                  </ListItem>
                </List>
              </Drawer>
            
          </AppBar>
        </MobileView>
      </>
    );
  }
  
}

  