import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { defaultProps } from "../App";
import NavBar from "../components/NavBar";
import { Typography } from "@mui/material";
import "./Program.css";

interface props extends defaultProps {}

const OmOss: React.FC<props> = () => {
  return (
    <div>
      <NavBar />

      <div className="wrapper">
        <div className="first">
          Akt.1
          <hr />
          <p>
            Vel møtt til konten <br></br>
            Stå to Survive<br></br>
            Bedpress Brainstorm<br></br>
            Mester Åge Vei<br></br>
            9:00-10:00<br></br>
            Kræsjkurs i Kræjkurs<br></br>
            Verving bog<br></br>
            Operation T.N.A.P.<br></br>
            VM i Skiskyting 2028<br></br>
            Leppetørkern<br></br>
            Svadaverv vs Zombies<br></br>
            TP<br></br>
            Personlighetstestinator<br></br>
            Sal det er no dritt<br></br>
            Løvene<br></br>
            Blue Hawk Down<br></br>
            Kaherregud<br></br>
            Quick fix<br></br>
          </p>
        </div>
        <div className="second">
          Akt.2
          <hr />
          <p>
            Stand with the Devil <br></br>
            Bestefars siste sjanse <br></br>
            Nordmenn for nordmenn <br></br>
            BI-ene <br></br>
            Eksamensjakk <br></br>
            Kontekongen <br></br>
            TikkTokk <br></br>
            Behind the Scenes <br></br>
            Ny reklamefilm <br></br>
            Er dette deg? <br></br>
            Brasiliansk rullett <br></br>
            Niemann tar konten <br></br>
            Kiltvandring <br></br>
            Bonden <br></br>
            Cava-Cava <br></br>
            Det heeelt rolige nabolaget <br></br>
            You've got mail <br></br>5 min igjen<br></br>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OmOss;
