import React from "react";
//import AppBar from "../components/AppBar";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Johny from "../bilderr/nyebilder/Johny Nguyen24.jpg";
import Micheal from "../bilderr/nyebilder/MikaelWaernessNy.jpg";
import Iver from "../bilderr/nyebilder/Iver Sten Gustavsen.jpg";
import Jens from "../bilderr/nyebilder/Jens Hovem Leonhardsen.jpg";
import Mads from "../bilderr/nyebilder/Mads Olav Eek.jpg";
import Ingrid from "../bilderr/nyebilder/Ingrid Roberg.jpg";
import Jenny from "../bilderr/nyebilder/Jenny Molander24.jpg";
import Vetle from "../bilderr/nyebilder/Vetle Frigstad.jpg"

const actors = [
  {
    Name: "Johny Nguyen",
    pic: Johny,
  },
  {
    Name: "Mikael Wærness",
    pic: Micheal,
  },
  {
    Name: "Jens Hovem Leonhardsen",
    pic: Jens,
  },
  {
    Name: "Mads Olav Eek",
    pic: Mads,
  },
  {
    Name: "Ingrid Roberg",
    pic: Ingrid,
  },
  {
    Name: "Iver Sten Gustavsen",
    pic: Iver,
  },
  {
    Name: "Jenny Molander",
    pic: Jenny,
  },
  {
    Name: "Vetle Frigstad",
    pic: Vetle,
  }
];

const Actors: React.FC<any> = ({ history }) => {
  return (
    <div>
      <div>
        <h1 className="h1-actors" style={{ color: "Black" }}>
          Skuespillerne 2024
        </h1>
      </div>
      <div className="">
        <Box
          sx={{
            flexGrow: 1,
            marginTop: 2,
            marginLeft: 2,
            marginRight: 2,
            marginBottom: 2,
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {actors.map((actor) => (
              <Grid item xs={12} sm={4} md={4}>
                <Card
                  sx={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="650"
                    image={actor.pic}
                    alt="bilde"
                  />
                  <CardContent>
                    <Typography>
                      <h3 style={{ textAlign: "center" }}>{actor.Name}</h3>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Actors;
