import React from "react";
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import Home from "./screens/Home";
import Images from "./screens/Images";
import OmOss from "./screens/OmOss";
import Program from "./screens/Program";
import Undergrupper from "./screens/Undergrupper";

export interface defaultProps extends RouteComponentProps {}

const App: React.FC = () => {
  return (
    <div>
      <head className="head">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>Hybridarevyen 2024</title>
      </head>

      <div className="">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <div className={"content-wrap"}>
          <BrowserRouter>
            <Switch>
              <Route path="/" exact render={(props) => <Home {...props} />} />
              <Route
                path="/Undergrupper"
                exact
                render={(props) => <Undergrupper {...props} />}
              />
              <Route
                path="/OmOss"
                exact
                render={(props) => <OmOss {...props} />}
              />
              <Route
                path="/Program"
                exact
                render={(props) => <Program {...props} />}
              />
              <Route
                path="/Bilder"
                exact
                render={(props) => <Images {...props} />}
              />
            </Switch>
          </BrowserRouter>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
