import React from "react";
import "./Footer.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BrowserView, MobileView } from "react-device-detect";

export const Footer: React.FC = () => {
  return (
    <>
      <BrowserView>
        <footer className="footer">
          <div className="footer-left col-md-4 col-sm-6">
            <p className="about">
              <span> Om oss </span> Hybridarevyen kom til live av noen revyglade
              ildsjeler på linja rundt denne tiden ifjor. Det startet med en
              drøm som nå har blitt til virkelighet. Vi håper dere har det minst
              halvparten så gøy når dere ser revyen som vi har hatt når vi har
              laget den.
            </p>
          </div>
          <div className="footer-center col-md-4 col-sm-6">
            <div className="adresse">
              <i className="fa fa-map-marker"></i>
              <p>
                <span> Kolbjørn Hejes vei 4</span> 7034 Trondheim
              </p>
            </div>
          </div>
          <div className="footer-right" style={{ float: "right" }}>
            <h2 className="footer-color">
              {" "}
              <span style={{ color: "black" }}>K</span>
              <span style={{ color: "#01529d" }}>O</span>
              <span style={{ color: "green" }}>N</span>
              <span style={{ color: "gray" }}>T</span>
              <span style={{ color: "#F64C72" }}>E</span>
              <span style={{ color: "skyblue" }}>N</span>
            </h2>
            <p className="menu">
              <a href="/"> Hjem</a> |
              <a href="https://www.ntnu.no/studier/mting"> Om oss</a> |
              <a href="/program"> Program</a>
            </p>
            <p className="name"> Konten &copy; 2023</p>
          </div>
        </footer>
      </BrowserView>
      <MobileView></MobileView>
    </>
  );
};

export default Footer;
